/* 布局 */
//前台
import Layout from "@views/layout";

const ordergravelatticedepositRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "ordergravelatticedeposit/list",
        name: "ORDERGRAVELATTICEDEPOSIT",
        meta: {
            title: "格位续费订单",
            keepAlive:true
        },
        component: () => import("@views/business/ordergravelatticedeposit/index.vue"),
    },
        ],
};

export default ordergravelatticedepositRouter;
