/* 布局 */
//前台
import Layout from "@views/layout";

const gravearearelativesRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "gravearearelatives/list",
        name: "GRAVEAREARELATIVES",
        meta: {
            title: "墓穴亲属管理",
            keepAlive:true
        },
        component: () => import("@views/business/gravearearelatives/index.vue"),
    },
        ],
};

export default gravearearelativesRouter;
