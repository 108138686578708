<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量

.side-bar {
  height: 99vh !important;
  overflow: auto;
  position: relative;
  background: $menuBg;

  .side-menu {
    height: calc(100% - 60px);
    overflow-y: auto;
    border: none;
  }

  .side-menu:not(.el-menu--collapse) {
    width: 220px;
  }
}
</style>
<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

.no-child.is-active {
  background-color: #003049 !important;
}

.el-menu-item-group {
  background-color: #f2f4f6 !important;
}

.el-menu-item-group ul,
.el-menu-item-group ul .el-menu-item {
  background-color: transparent;
}

.el-menu-item-group ul .el-menu-item {
  background-color: transparent;
  border-bottom: 1px solid #e5e6eb;
}

.el-menu-item-group ul .el-menu-item:last-child {
  border-bottom: none !important;
}

.el-menu-item-group .is-active {
  background-color: #003049 !important;
}

.el-menu-item-group .el-menu-item-group__title {
  display: none !important;
}

.el-aside {
  padding-top: 15px;
  box-shadow: 3px 0 3px rgba(0, 21, 41, 0.08);
}

.el-container {
  margin-left: 3px;
}

.el-menu--collapse .el-menu-item {
  text-align: center !important;
  font-size: 18px !important;

}

.el-submenu__title span {
  padding-left: 5px;
}

.el-menu-item {
  padding-left: 10px !important;
}
</style>

<template>
  <section class="side-bar" style="background:#ffffff;" id="try-side">
    <el-menu class="side-menu" unique-opened router background-color="transparent" text-color="#000000"
      active-text-color="#ffffff" :default-active="active" :collapse="isCollapse">
      <SidebarGroup :isCollapse="isCollapse" v-for="mitem in menulist" :key="mitem.amenu_id" :index="mitem.amenu_id + ''"
        :item="mitem" />
    </el-menu>
  </section>
</template>

<script>
import { getUserMenu } from "@api/AdminMenu";
import SidebarGroup from "./SidebarGroup";

export default {
  //组件
  components: {
    SidebarGroup,
  },
  name: "Sidebar",
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    leftmenu: {
      type: [String, Number, Array, Date],
      default: ""
    },
    leftactive: {
      type: [String],
      default: ""
    },
  },
  data() {
    return {
      // active: "/homepage",
      active: this.leftactive,
      menulist: this.leftmenu,
    };
  },
  watch: {
    $route(val) {
      // this.active = val.path;
      // this.active = val.fullPath;//使用完全体URL处理数据
      this.active = val.meta.parentPath ? val.meta.parentPath : val.fullPath;//使用完全体URL处理数据

    },
    leftmenu(val) {
      this.menulist = val;
    },
  },
  created() {
    // this.active = this.$route.path;
    this.active = this.$route.meta.parentPath ? this.$route.meta.parentPath : this.$route.fullPath;
  },
  methods: {

  },
};
</script>
