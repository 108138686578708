/* 布局 */
//前台
import Layout from "@views/layout";

const reservationcremationRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "reservationcremation/list",
        name: "RESERVATIONCREMATION",
        meta: {
            title: "遗体火化地点管理",
            keepAlive:true
        },
        component: () => import("@views/business/reservationcremation/index.vue"),
    },
        ],
};

export default reservationcremationRouter;
