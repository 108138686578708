/* 布局 */
//前台
import Layout from "@views/layout";

const exbannerclassRouter = {
    path: "/modular",
    component: Layout,
    redirect: {
        name: "MODULAR"
    },
    children: [
        {
        path: "exbannerclass/list",
        name: "EXBANNERCLASS",
        meta: {
            title: "轮播图分类",
        },
        component: () => import("@views/modular/exbannerclass/index.vue"),
    },
        ],
};

export default exbannerclassRouter;
