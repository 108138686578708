/* 布局 */
//前台
import Layout from "@views/layout";

const goodsflowerRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "goodsflower/list",
        name: "GOODSFLOWER",
        meta: {
            title: "鲜花套餐管理",
            keepAlive:true
        },
        component: () => import("@views/business/goodsflower/index.vue"),
    },
        ],
};

export default goodsflowerRouter;
