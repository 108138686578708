/* 布局 */
//前台
import Layout from "@views/layout";

const ordergraveRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "ordergrave/list",
        name: "ORDERGRAVE",
        meta: {
            title: "墓穴购买订单",
            keepAlive:true
        },
        component: () => import("@views/business/ordergrave/index.vue"),
    },
        ],
};

export default ordergraveRouter;
