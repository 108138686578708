/* 布局 */
//前台
import Layout from "@views/layout";

const gravelatticeaddressRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "gravelatticeaddress/list",
        name: "GRAVELATTICEADDRESS",
        meta: {
            title: "墓区格位详情",
            keepAlive:true
        },
        component: () => import("@views/business/gravelatticeaddress/index.vue"),
    },
        ],
};

export default gravelatticeaddressRouter;
