/* 布局 */
//前台
import Layout from "@views/layout";

const goodswarehouselogRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "goodswarehouselog/list",
        name: "GOODSWAREHOUSELOG",
        meta: {
            title: "商品出入库记录",
            keepAlive:true
        },
        component: () => import("@views/business/goodswarehouselog/index.vue"),
    },
        ],
};

export default goodswarehouselogRouter;
