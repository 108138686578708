<template>
  <div id="attachmentCate">
    <!-- <el-button class="allCate" @click="append">添加分类</el-button> -->
    <el-tree ref="tree" class="filter-tree" :data="treeData" :props="defaultProps" highlight-current
      :expand-on-click-node="showTree" :indent="12" node-key="id" :current-node-key="currentNode"
      :default-expanded-keys="defaultExpandKeys" @node-click="handleNodeClick">
      <template slot-scope="{ node, data }">
        <div class="flex-row treeSortList">
          <span class="tooltip-wrap">{{ node.label }}</span>
          <div v-if="showBtn[data.ac_id]" class="doBtn">
            <el-button v-if="showAdd" type="text" size="mini" title="添加" icon="el-icon-plus"
              @click.stop="() => append(data)" />
            <el-button v-if="showEdit" type="text" size="mini" title="编辑" icon="el-icon-edit-outline"
              @click.stop="() => edit(data, node)" />
            <el-button v-if="showDele" type="text" size="mini" title="删除" icon="el-icon-delete"
              @click.stop="() => remove(data, node)" />
            <!--                                            <el-button type="text" v-if="data.parent_id != 0" size="small" title="菜单权限"-->
            <!--                                                       @click.stop="() => setMenu(node, data)" icon="el-icon-setting">-->
            <!--                                            </el-button>-->
          </div>
        </div>
      </template>
    </el-tree>
    <!-- {{ treeData }} -->
    <!-- <div style="padding: 0 20px 20px">
                <el-button
                  @click="openDialog(0)"
                  class="el-icon-plus light_blue"
                  style="padding:13px 64px; font-size:14px"
                  >新增部门
                </el-button>
              </div> -->

    <!-- {{ currentNode }}{{ defaultNode }}
    <el-button @click="dianji">点击</el-button> -->
    <try-dialog :title="form.ac_id == 0 ? '增加分类' : '编辑分类'" :visible.sync="dialogShow" width="800px" :append="true"
      @close="dialogShow = false">
      <div style="padding-bottom: 40px">
        <el-form ref="form" :model="form" label-width="100px" :rules="formRules">
          <el-form-item label="上级分类" prop="ac_parent">
            <el-select v-model="form.ac_parent">
              <!-- <el-option label="一级分类" :value="0" /> -->
              <el-option v-for="item in cateList" :key="item.ac_id" :label="item.ac_title" :value="item.ac_id" />
            </el-select>
          </el-form-item>
          <el-form-item label="分类名称" prop="ac_title">
            <el-input v-model="form.ac_title" />
          </el-form-item>
          <!-- <el-form-item label="自定义文件夹" prop="path">
            <el-input v-model="form.path" />
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogShow = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmit()"> 确 定</el-button>
        </div>
      </div>

    </try-dialog>

  </div>
</template>

<script>


import {
  getAttachmentCateList,
  getAttachmentCateInfo,
  editAttachmentCate,
  addAttachmentCate,
  deleteAttachmentCate,
} from "@api/AttachmentCate";
export default {
  name: 'TryAttachmentcate',
  components: {},
  data() {
    return {
      currentNode: 0,
      defaultExpandKeys: [],
      dialogShow: false,
      form: {
        ac_id: 0,
        ac_parent: 0,
        ac_path: '',
        ac_title: ''
      },
      pagination: {
        page: 1,
        total: 0, //总数
        current_page: 1, //当前页默认第一页
        size: 10, //每页数量
        sizes: [10, 15, 20, 25, 30], //分页模式
      }, //分页数据
      formRules: {
        ac_parent: [
          { required: true, message: '请选择上级分类', trigger: 'change' }
        ],
        ac_title: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
      },
      cate_id: '',
      cateList: [],
      treeData: [],
      // data: JSON.parse(JSON.stringify(data)),
      defaultProps: {
        children: 'children',
        label: 'ac_title'
      },
      showTree: false, // 是否点击节点展开树，false 只能点前面三角图标展开
      showAdd: true,
      showBtn: [],
      showEdit: false,
      showDele: false
    }
  },
  watch: {

  },
  created() {
    this.initData()
  },
  activated() {
    this.initData();
  },
  methods: {
    /*初始化数据*/
    async initData() {
      this.getCateList(0)
    },
    // 附件类别
    async getCateList(val) {
      this.loading = true;
      const pages = {
        pageindex: this.pagination.current_page,
        pagesize: this.pagination.size,
      };
      var res;
      var params = {};
      params.title = val == 0 ? '' : val;
      res = await getAttachmentCateList(params, pages);

      if (res.status == 200) {
        if (val == 0) {
          this.currentNode = 0
          this.showBtn = []
          this.$set(this.showBtn, val, true)
          this.showEdit = false
          this.showDele = false
          this.cate_id = val
          // this.handleNodeClick()
        }
        this.$emit('update', 0);
        this.treeData = res.data;
        this.cateList = JSON.parse(JSON.stringify(res.data))
        this.cateList[0].title = '一级分类'
        this.defaultExpandKeys = [this.currentNode]
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.currentNode)
        })
      }

    },
    // 点击分类
    handleNodeClick(data) {
      this.currentNode = data.ac_id
      this.form.ac_id = data.ac_id
      // 调用父组件的方法请求附件列表
      this.$emit('update', data.ac_id)
      // 判断是否显示新增用户按钮
      if (data.ac_parent != 0) {
        this.showAdd = false
      } else {
        this.showAdd = true
      }
      // 展示增删改icon
      if (data.ac_id == 0) {
        this.showEdit = false
        this.showDele = false
      } else {
        this.showEdit = true
        this.showDele = true
      }
      this.showBtn = []
      this.$set(this.showBtn, data.ac_id, true)
    },
    // 新增分类
    append(data) {
      this.dialogShow = true
      this.form.ac_id = 0;
      this.form.ac_parent = data.ac_id
      this.form.ac_title = ''
    },
    // 编辑分类
    edit(data) {
      // console.log(data, '------------------')
      this.dialogShow = true
      this.form = data

    },
    // 删除分类
    remove(data) {
      // console.log(data)
      // return
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        /*请求接口删除数据*/
        const res = await deleteAttachmentCate(data.ac_id);
        if (res.status != 200) {
          this.$message.error(res.msg)
          return;
        }

        this.$message({
          type: "success",
          message: res.msg
        });
        this.infoDrawerVisible = false;
        this.getCateList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 提交编辑、新增分类
    /** 提交表单*/
    async handleSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          var res;
          let submitFormData = JSON.parse(JSON.stringify(this.form));
          console.log(submitFormData);
          /** 请求編輯接口*/
          if (submitFormData.ac_id) {
            res = await editAttachmentCate(submitFormData);
          } else {
            /** 请求新增接口*/
            res = await addAttachmentCate(submitFormData);
          }
          if (res.status != 200)
            return;
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.dialogShow = false;
          this.getCateList();// 处理成功，调用方法关闭弹窗
          // this.handleCancle(); // 处理成功，调用方法关闭弹窗
        }
      });
    },

  }
}
</script>
<style lang="scss">
.dialog_cate {
  .el-select {
    width: 100%;
  }

}

#attachmentCate {
  height: 400px;

  .allCate {
    height: 30px;
  }

  .el-scrollbar {
    height: 100%;
  }

  .el-scrollbar__thumb {
    width: 0;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  // 添加部门时设置菜单
  .el-tree.form-tree {
    padding: 10px 20px;
  }

  .el-tree {
    padding: 20px 0;
    font-size: 14px;
  }

  .treelistCont {
    width: 500px;
  }

  /* 高亮显示选中 */
  .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    color: #2b5fd6;
    background-color: #eff5ff;
    border-radius: 6px;

    .el-tree-node__expand-icon {
      color: #1465ff;
    }

    .el-tree-node__expand-icon.is-leaf {
      color: transparent;
    }
  }

  .el-tree-node__content {
    height: 44px;
    line-height: 20px;
  }

  .el-tree-node__content .el-input {
    width: auto;
    min-width: 60px;
  }

  .treeSortList {
    width: calc(100% - 28px);
    align-items: center;
    justify-content: space-between;
    position: relative;
    display: flex;

    .tooltip-wrap {
      width: calc(100% - 28px) !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .treeSortList .doBtn {
    width: 62px;
    z-index: 999;
    text-align: right;

    .el-button--mini {
      padding: 0;
    }
  }

  .treeSortList .doBtn .el-button--text {
    color: #2b5fd6;
  }

  .treeSortList .doBtn .el-button--text i {
    color: #fff;
  }

  .treeSortList .doBtn .el-button--text i.el-icon-delete {
    color: #f77501;
  }

  .treeSortList .doBtn .el-button--text i.el-icon-plus {
    color: #2b5fd6;
  }

  .treeSortList .doBtn .el-button--text i.el-icon-edit-outline {
    color: #2b5fd6;
  }

  .treeSortList .doBtn .el-button--text i.el-icon-setting {
    color: #2b5fd6;
  }
}
</style>
<style scoped></style>
