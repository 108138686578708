/* 布局 */
//前台
import Layout from "@views/layout";

const burialuserRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "burialuser/list",
        name: "BURIALUSER",
        meta: {
            title: "逝者信息管理",
            keepAlive:true
        },
        component: () => import("@views/business/burialuser/index.vue"),
    },
        ],
};

export default burialuserRouter;
