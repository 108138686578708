import API from "@utils/request";
import { PAGE_SIZE } from "@config";
import store from "../store";
const ADRESS = "/api/user/";

/**
* 获取用户管理列表（分页）
* @author 四川创企
* @DateTime 2023/12/29
* @param {object} params
* @param {object} pages
*/
export async function getUserList(params, pages) {
    try {
        return await API.get(ADRESS + "list", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

/**
* 远程搜索
* @author 四川创企
* @DateTime 2023/12/29
* @param {object} params
* @param {object} pages
*/
export async function remoteSelect(params, pages) {
    try {
        return await API.get(ADRESS + "remoteselect", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

/**
* 获取用户管理详情
* @author 四川创企
* @DateTime 2023/12/29
* @param {number} id
*/
export async function getUserInfo(id) {
    try {
        return await API.post(ADRESS + "info", {
            id: id,
        });
    } catch (error) {
        return error;
    }
}
/**
* 编辑用户管理
* @author 四川创企
* @DateTime 2023/12/29
* @param {object} params
*/
export async function editUser(params) {
    try {
        return await API.post(ADRESS + "edit", params);
    } catch (error) {
        return error;
    }
}

/**
* 新增用户管理
* @author 四川创企
* @DateTime 2023/12/29
* @param {object} params
*/
export async function addUser(params) {
    try {
        return await API.post(ADRESS + "add", params);
    } catch (error) {
        return error;
    }
}
/**
* 刪除用户管理
* @author 四川创企
* @DateTime 2023/12/29
* @param {number} id
*/
export async function deleteUser(id) {
    try {
        return await API.post(ADRESS + "delete", {
            id: {
                id,
            },
        });
    } catch (error) {
        return error;
    }
}
/**
* 获取用户管理键值对
* @author 四川创企
* @DateTime 2023/12/29
* @param {object} key
* @param {object} value
*/
export async function getUserKeyVal(key, value, params = {}) {
    try {
        return await API.post(ADRESS + "getkeyval", {
            key: key,
            value: value,
            params: params
        });
    } catch (error) {
        return error;
    }
}


