/* 布局 */
//前台
import Layout from "@views/layout";

const userRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "user/list",
            name: "USER",
            meta: {
                title: "用户管理",
                keepAlive: true
            },
            component: () => import("@views/business/user/index.vue"),
        },
    ],
};

export default userRouter;
