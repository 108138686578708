/* 布局 */
//前台
import Layout from "@views/layout";

const ordertombstoneRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "ordertombstone/list",
        name: "ORDERTOMBSTONE",
        meta: {
            title: "模板刻字订单",
            keepAlive:true
        },
        component: () => import("@views/business/ordertombstone/index.vue"),
    },
        ],
};

export default ordertombstoneRouter;
