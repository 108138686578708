/* 布局 */
//前台
import Layout from "@views/layout";

const driverusertaskRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "driverusertask/list",
            name: "DRIVERUSERTASK",
            meta: {
                title: "司机历史任务",
                keepAlive: true
            },
            component: () => import("@views/business/driverusertask/index.vue"),
        }, {
            path: "driverusertask/detail",
            name: "DRIVERUSERTASK_DETAIL",
            meta: {
                title: "任务详情",
                operate: true,
                parentPath: "/business/driverusertask/list",
            },
            component: () => import("@views/business/driverusertask/detail.vue"),
        }
    ],
};

export default driverusertaskRouter;
