import md5 from 'js-md5'
import SparkMD5 from 'spark-md5'
import API from "@utils/request";
import { UPLOAD_FILE_SLICE } from "@config";

/*
 * 分片上传函数 支持多个文件
 * @param options
 * options.file 表示源文件
 * options.pieceSize 表示需要分片的大小 默认是5m
 * options.chunkUrl 分片上传的后端地址
 * options.fileUrl 整个文件的上传地址
 * progress 进度回调
 * success 成功回调
 * error 失败回调
 *
 */
export const uploadByPieces = ({ files, chunkUrl, preUrl, progress, success, error }) => {
  if (!files || !files.length) return

  //   const resourceTempBaseName = '' // 预处理返回
  //   const resourceTempBaseName = new SparkMD5.ArrayBuffer()
  //const md5 = require('md5')
  const date = new Date().getMilliseconds()
  const resourceTempBaseName = md5(files[0].name + date)
  const chunkCount = '' // 预处理返回
  const chunkSize = ''
  // 上传过程中用到的变量
  let progressNum = 1 // 进度
  let successAllCount = 0 // 上传成功的片数
  const currentAllChunk = 0 // 当前上传的片数索引
  let AllChunks = 0 // 所有文件的chunk数之和
  const UploadFile = ''
  let currentFile
  let chunk_size = 5120
  let chunk_condition = ''
  /*systemApi.sysList().then(res => {
    if (res.data[1].children[1].value * 1 == 0) {
      const chunk_size = 2048
    } else {
      chunk_size = res.data[1].children[1].value * 1
    }
    chunk_condition = res.data[1].children[10].value * 1
    console.log(chunk_size)// 系统设置的分片大小
  })*/
  // 获取md5
  const readFileMD5 = (files) => {
    calculateHash()
  }
  const calculateHash = () => {
    const file = files[0]
    const blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice

    const chunkSize = chunk_size * 1024 // Read in chunks of 2MB

    const chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = function(e) {
      spark.append(e.target.result) // Append array buffer
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        uploadChunk()
      }
    }
    fileReader.onerror = function() {
      console.warn('oops, something went wrong.')
    }
    function loadNext() {
      const start = currentChunk * chunkSize
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }
    loadNext()
  }
  const getChunkInfo = (file, currentChunk, chunkSize) => {
    const start = currentChunk * chunkSize
    const end = Math.min(file.size, start + chunkSize)
    const chunk = file.slice(start, end)
    return chunk
  }
  const sleep = (delay) => {
    var start = (new Date()).getTime();
    while ((new Date()).getTime() - start < delay) {
      continue;
    }
  }
  // 更新进度
  const progressFun = () => {
    progressNum = Math.ceil((successAllCount / AllChunks) * 100)
    progress(progressNum)
  }
  // 分片上传

  const uploadChunk = () => {
    const type = files[0].name.substring(files[0].name.lastIndexOf('.') + 1)
    const currentFile = files[0]
    const chunkSize = chunk_size * 1024
    const chunkCount = Math.ceil(currentFile.size / chunkSize)
    AllChunks = AllChunks + chunkCount
    const UploadFile = currentFile
    const fileName = files[0].name

    uploadslice(0,chunkCount,resourceTempBaseName,fileName,UploadFile,chunkSize,type);

  }
  const uploadslice = async (i,chunkCount,resourceTempBaseName,fileName,UploadFile,chunkSize,type) => {
    const chunk = getChunkInfo(UploadFile, i, chunkSize)
    const fetchForm = new FormData()
    fetchForm.append('resource_chunk', chunk)
    fetchForm.append('chunk_total', chunkCount)
    fetchForm.append('chunk_index', i + 1)
    fetchForm.append('resource_temp_basename', resourceTempBaseName + '__' + (i + 1))
    fetchForm.append('resource_temp_path', resourceTempBaseName)
    fetchForm.append('resource_type', type)
    fetchForm.append('resource_name', fileName)
    fetchForm.append('accessToken', sessionStorage.getItem("token"));
    console.log(fetchForm);
    let res = await API.post(UPLOAD_FILE_SLICE, fetchForm);
    if (res.status == 1) {
      successAllCount++
      progressFun();
      i++;
      if(i < chunkCount){
        uploadslice(i,chunkCount,resourceTempBaseName,fileName,UploadFile,chunkSize,type);
      }
    } else if (res.status == 200) {
      successAllCount++
      progressFun()
      success && success(res.msg)
    }else{
      error && error(res.msg)
    }
  }
  readFileMD5(files) // 开始执行代码
}
