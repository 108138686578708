/* 布局 */
//前台
import Layout from "@views/layout";

const sacrificehomebiographyRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "sacrificehomebiography/list",
        name: "SACRIFICEHOMEBIOGRAPHY",
        meta: {
            title: "云祭祀房间生平",
            keepAlive:true
        },
        component: () => import("@views/business/sacrificehomebiography/index.vue"),
    },
        ],
};

export default sacrificehomebiographyRouter;
