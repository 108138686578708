/* 布局 */
//前台
import Layout from "@views/layout";

const goodsRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "goods",
            name: "GOODS",
            meta: {
                title: "商品管理",
                sort: 1,
            },
            component: () => import("@views/business/goods/index.vue"),
            redirect: {
                name: "GOODS_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "GOODS_LIST",
                    meta: {
                        title: "商品管理",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/goods/list.vue"),
                },
                {
                    path: "add",
                    name: "GOODS_ADD",
                    meta: {
                        title: "新增商品管理",
                        parentPath: '/business/goods/list',
                        operate: true,
                    },
                    component: () => import("@views/business/goods/form.vue"),
                },
                {
                    path: "edit",
                    name: "GOODS_EDIT",
                    meta: {
                        title: "编辑商品管理",
                        parentPath: '/business/goods/list',
                        operate: true,
                    },
                    component: () => import("@views/business/goods/form.vue"),
                },
            ],
        }, {
            path: "goods/search",
            name: "GOODSSEARCH",
            meta: {
                title: "商品查询",
                keepAlive: true
            },
            component: () => import("@views/business/goods/search.vue"),
        }, 
        {
            title: '扫码页面',
            name: 'scanCodePage',
            path: '/scanCodePage',
            component: () => import("@views/business/goods/scanCodePage.vue"),
        }
    ],
};

export default goodsRouter;
