/* 布局 */
//前台
import Layout from "@views/layout";

const gravelatticecollectionRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "gravelatticecollection/list",
        name: "GRAVELATTICECOLLECTION",
        meta: {
            title: "墓区格位申领管理",
            keepAlive:true
        },
        component: () => import("@views/business/gravelatticecollection/index.vue"),
    },
        ],
};

export default gravelatticecollectionRouter;
