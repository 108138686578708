<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

.badge-item {
  .el-badge__content.is-fixed {
    top: 20px !important;
    right: 3px !important;
  }
}
</style>


<template>
  <!-- 有子集 -->

  <el-submenu v-if="item.children.length > 0" :index="item.amenu_id + ''">
    <template slot="title">
      <i :class="item.amenu_icon"></i>
      <span slot="title">{{ item.amenu_name }}</span>
    </template>
    <el-menu-item-group>
      <SidebarGroup v-for="subItem in item.children" :key="subItem.id" :item="subItem" />
    </el-menu-item-group>
  </el-submenu>

  <!-- 无子集 -->
  <el-menu-item class="no-child" :index="item.amenu_address + ''" v-else>
    <el-tooltip class="item" effect="dark" :content="item.amenu_name" placement="right" v-if="isCollapse">
      <!-- span是button的外层标签 -->
      <i :class="item.amenu_icon"></i>
    </el-tooltip>
    <i :class="item.amenu_icon" v-else></i>
    <el-badge :value="item.wait_num" class="badge-item" :hidden="item.wait_num==0">
    {{ isCollapse == false ? item.amenu_name : "" }}
    </el-badge>
  </el-menu-item>
</template>

<script>
export default {
  name: "SidebarGroup",
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    item: {
      type: [Array, Object],
      default: ""
    },
  },
  data() {
    return {
    };
  },
  methods: {
    //点击跳转
    clickMenu(item) {
      this.$router.push({
        name: item.name,
        title: item.name
      });

      //设置tags导航
      this.$store.commit("selectMent", item)
    },
  }
};
</script>
