/* 布局 */
//前台
import Layout from "@views/layout";

const driveruserRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "driveruser/list",
        name: "DRIVERUSER",
        meta: {
            title: "司机用户管理",
            keepAlive:true
        },
        component: () => import("@views/business/driveruser/index.vue"),
    },
        ],
};

export default driveruserRouter;
