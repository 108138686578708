/* 布局 */
//前台
import Layout from "@views/layout";

const sacrificehomemusicRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "sacrificehomemusic/list",
        name: "SACRIFICEHOMEMUSIC",
        meta: {
            title: "云祭祀音乐管理",
            keepAlive:true
        },
        component: () => import("@views/business/sacrificehomemusic/index.vue"),
    },
        ],
};

export default sacrificehomemusicRouter;
