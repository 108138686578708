/* 布局 */
//前台
import Layout from "@views/layout";

const graverepairRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "graverepair/list",
        name: "GRAVEREPAIR",
        meta: {
            title: "公墓维修申请",
            keepAlive:true
        },
        component: () => import("@views/business/graverepair/index.vue"),
    },
        ],
};

export default graverepairRouter;
