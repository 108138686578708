/* 布局 */
//前台
import Layout from "@views/layout";

const goodsservermealRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "goodsservermeal/list",
        name: "GOODSSERVERMEAL",
        meta: {
            title: "VIP服务套餐",
            keepAlive:true
        },
        component: () => import("@views/business/goodsservermeal/index.vue"),
    },
        ],
};

export default goodsservermealRouter;
