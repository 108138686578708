/* 布局 */
//前台
import Layout from "@views/layout";

const exbannerRouter = {
    path: "/modular",
    component: Layout,
    redirect: {
        name: "MODULAR"
    },
    children: [
        {
        path: "exbanner/list",
        name: "EXBANNER",
        meta: {
            title: "轮播图管理",
        },
        component: () => import("@views/modular/exbanner/index.vue"),
    },
        ],
};

export default exbannerRouter;
