/* 布局 */
//前台
import Layout from "@views/layout";

const goodsclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "goodsclass/list",
            name: "GOODSCLASS",
            meta: {
                title: "商品分类管理",
                keepAlive: true
            },
            component: () => import("@views/business/goodsclass/index.vue"),
        },
    ],
};

export default goodsclassRouter;
