/* 布局 */
//前台
import Layout from "@views/layout";

const ordercartRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "ordercart/list",
        name: "ORDERCART",
        meta: {
            title: "租车预约订单",
            keepAlive:true
        },
        component: () => import("@views/business/ordercart/index.vue"),
    },
        ],
};

export default ordercartRouter;
