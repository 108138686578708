/* 布局 */
//前台
import Layout from "@views/layout";

const ordersacrificematerialRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "ordersacrificematerial/list",
        name: "ORDERSACRIFICEMATERIAL",
        meta: {
            title: "云祭祀素材订单",
            keepAlive:true
        },
        component: () => import("@views/business/ordersacrificematerial/index.vue"),
    },
        ],
};

export default ordersacrificematerialRouter;
