/* 布局 */
//前台
import Layout from "@views/layout";

const driverusertasklogRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "driverusertasklog",
            name: "DRIVERUSERTASKLOG",
            meta: {
                title: "司机出车记录",
                sort: 1,
            },
            component: () => import("@views/business/driverusertasklog/index.vue"),
            redirect: {
                name: "DRIVERUSERTASKLOG_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "DRIVERUSERTASKLOG_LIST",
                    meta: {
                        title: "司机出车记录",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/driverusertasklog/list.vue"),
                },
                {
                    path: "add",
                    name: "DRIVERUSERTASKLOG_ADD",
                    meta: {
                        title: "新增司机出车记录管理",
                        parentPath: '/business/driverusertasklog/list',
                        operate: true,
                    },
                    component: () => import("@views/business/driverusertasklog/form.vue"),
                },
                {
                    path: "edit",
                    name: "DRIVERUSERTASKLOG_EDIT",
                    meta: {
                        title: "编辑司机出车记录管理",
                        parentPath: '/business/driverusertasklog/list',
                        operate: true,
                    },
                    component: () => import("@views/business/driverusertasklog/form.vue"),
                },
                {
                    path: "track",
                    name: "DRIVERUSERTASKLOG_TRACK",
                    meta: {
                        title: "轨迹地图",
                        parentPath: '/business/driverusertasklog/list',
                        operate: true,
                    },
                    component: () => import("@views/business/driverusertasklog/track.vue"),
                },
            ],
        },


    ],
};

export default driverusertasklogRouter;
