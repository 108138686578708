<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

body {
  overflow-x: hidden;
}

.el-container {
  margin-left: 0px !important;
}

.vue-body {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .el-aside {
    width: auto !important;

    .side-bar {
      height: 100%;
      flex-shrink: 0;
    }
  }

  .body-container {
    margin-left: 0px !important;
    height: 100%;

    .el-header {
      padding: 0;
      height: 50px !important;
    }

    .el-main {
      width: 100%;
      padding: 0;
      height: calc(100%);

      .app-main {
        width: 100%;
        height: 100%;
      }
    }

    .el-footer {
      height: 72px;
      line-height: 72px;
      font-size: 14px;
      color: $color-text-secondary;
      text-align: center;
      background: $body-background;
    }
  }
}
</style>

<template>
  <div id="layout">
    <el-container class="vue-body">
      <el-container class="body-container">
        <el-header id="try-navBar">
          <navbar
            @handleChangeCollapse="changeCollapse"
            :isCollapse="isCollapse"
            :menulist="menulist"
            :active="activeMenu"
            @changeLeftMenu="changeLeftMenu"
          />
        </el-header>
        <el-container>
          <el-aside>
            <sidebar :isCollapse="isCollapse" :leftmenu="leftmenu" />
          </el-aside>
          <el-main>
            <app-main @changeMenu="changeMenu" :menuListArr="menulist" />
          </el-main>
        </el-container>
        <!-- <el-footer> {{copyright}} </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import autofit from "autofit.js";
import Navbar from "./components/Navbar";
import AppMain from "./components/AppMain";
import Sidebar from "./components/Sidebar";
import { getGroupItemValue } from "@api/AdminUser";
import { getUserMenu } from "@api/AdminMenu";
import { mapActions } from "vuex";
export default {
  name: "Layout",
  components: {
    Navbar,
    AppMain,
    Sidebar,
  },
  data() {
    return {
      isCollapse: false,
      copyright: "", // 版权初始化数据
      menulist: [],
      leftmenu: [],
      activeMenu: "",
    };
  },
  computed: {
    isMobile: () => {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },

  created() {
    // 获取初始化数据
    this.getData();
    this.setDictionaries();
    this.setDicttree();
    this.setDataConfig();
    this.setBusinessData();
  },
  mounted() {
    let width = window.innerWidth;
    if (width < 1930) {
      autofit.init(
        {
          dh: 980,
          dw: 1930,
          el: "#layout",
          resize: true,
        },
        false
      );
    }
    console.log(this.isMobile)
    if (this.isMobile) {
      /* ipad pro */
      if (width <= 1366 && width > 1180) {
        autofit.init(
          {
            dh: 1024,
            dw: 1366,
            el: "#layout",
            resize: true,
          },
          false
        );
      }
      /* ipad air */
      if (width <= 1180 && width > 1024) {
        autofit.init(
          {
            dh: 820,
            dw: 1180,
            el: "#layout",
            resize: true,
          },
          false
        );
      }
      /* ipad */
      if (width <= 1024) {
        autofit.init(
          {
            dh: 768,
            dw: 1024,
            el: "#layout",
            resize: true,
          },
          false
        );
      }
    }
    this.CreatMenu();
  },
  methods: {
    ...mapActions("dictionaries", ["setDictionaries"]),
    ...mapActions("dicttree", ["setDicttree"]),
    ...mapActions("configdata", ["setDataConfig"]),
    ...mapActions("businessdata", ["setBusinessData"]),

    changeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    async getData() {
      let { data: restitle } = await getGroupItemValue("COPYRIGHT");
      this.copyright = restitle.dgi_value
        ? restitle.dgi_value
        : "Copyright © 2022 四川创企科技有限责任公司 版权所有";
    },
    async CreatMenu() {
      let that = this;
      let { data: res } = await getUserMenu();

      if (!res) return;

      this.menulist = res;
      sessionStorage.setItem("menuArr", JSON.stringify(this.menulist));
      try {
        this.menulist.forEach(function (child, key) {
          child.children.forEach(function (item, index) {
            if (item.children.length > 0) {
              item.children.forEach(function (item2, index2) {
                if (item2.amenu_address == that.$route.fullPath) {
                  that.activeMenu = that.menulist[key].amenu_id + "";
                  that.leftmenu = child.children;
                  throw new Error("1");
                }
              });
            } else {
              if (item.amenu_address == that.$route.fullPath) {
                that.activeMenu = that.menulist[key].amenu_id + "";
                that.leftmenu = child.children;
                throw new Error("1");
              }
            }
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    changeLeftMenu(e) {
      this.leftmenu = e;
    },
    changeMenu(e, parentPath) {
      let that = this;
      try {
        this.menulist.forEach(function (child, key) {
          child.children.forEach(function (item, index) {
            if (item.children.length > 0) {
              item.children.forEach(function (item2, index2) {
                if (
                  item2.amenu_address == e ||
                  item2.amenu_address == parentPath
                ) {
                  that.activeMenu = that.menulist[key].amenu_id + "";
                  that.leftmenu = child.children;
                  throw new Error("1");
                }
              });
            } else {
              if (item.amenu_address == e || item.amenu_address == parentPath) {
                that.activeMenu = that.menulist[key].amenu_id + "";
                that.leftmenu = child.children;
                throw new Error("1");
              }
            }
          });
        });
      } catch (e) {}
    },
  },
};
</script>
