/* 布局 */
//前台
import Layout from "@views/layout";

const sacrificehomematerialRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "sacrificehomematerial/list",
        name: "SACRIFICEHOMEMATERIAL",
        meta: {
            title: "云祭祀房间素材管理",
            keepAlive:true
        },
        component: () => import("@views/business/sacrificehomematerial/index.vue"),
    },
        ],
};

export default sacrificehomematerialRouter;
