<template>
  <div class="images-list">
    <el-upload class="upload-demo" :action="uploadUrl" :data="uploadData" :before-upload="handleBeforeUpload"
      :on-success="handleSuccess" :on-error="handleUploadError" :on-remove="handleRemove" :on-exceed="handleExceed"
      :file-list="fileList" :multiple="upOption.fileLimit > 1" :headers="paramsData" :limit="upOption.fileLimit"
      :list-type="listType" :drag="listType === 'text'" :disabled="disabled">
      <i v-if="listType === 'picture-card'" class="el-icon-plus"></i>
      <i v-else-if="listType === 'text'" class="el-icon-upload"></i>
      <el-button v-else size="small" type="primary">点击上传</el-button>
      <div v-if="listType === 'text'" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div v-if="showTip" slot="tip" class="el-upload__tip">
        只能上传{{ fileTypeName || "jpg/png" }}文件，且不超过 {{ upOption.fileSize }}MB
      </div>
    </el-upload>
  </div>
</template>
<script>
import { getGroupData } from "@api/DataGroup";
import { UPLOAD_IMG_URL, UPLOAD_FILE_URL, UPLOAD_FILE_SLICE, UPLOAD_FILE_INPORT } from "@config";
export default {
  name: "TryUpload",
  props: {
    // 值
    value: [String, Object, Array],
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如["doc", "xls", "ppt", "txt", "pdf"]
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg", "gif"],
    },
    // 是否使用提交参数
    isUseSubType: {
      type: Number,
      default: 2,
    },
    uploadData: {
      type: Object,
      default: () => {
      },
    },
    //上传文件类型
    upType: {
      type: String,
      default: 'IMG'
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    // 最大允许上传个数
    fileLimit: {
      type: Number,
      default: 5,
    },
    //是否为导入组件
    isImport: {
      type: Boolean,
      default: false
    },
    disabled: {//禁用属性
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadUrl: UPLOAD_IMG_URL, // 上传的图片服务器地址
      paramsData: {
        "accessToken": sessionStorage.getItem("token"),
        "charset": "utf-8"
      }, // 上传携带的参数，看需求要不要
      fileList: [],
      tempFileList: [], // 因为 fileList为只读属性，所以用了一个中间变量来进行数据改变的交互。
      upOption: {
        "fileSize": this.fileSize,
        "fileType": this.fileType,
        "fileLimit": this.fileLimit,
      },
      // 文件列表类型 text/picture/picture-card
      listType: 'picture',
      isUseSubmitType: this.isUseSubType,
    };
  },
  watch: {
    value: {
      handler: function (newVal, oldVa) {
        if (newVal != "" && newVal != undefined) {
          let imgs = JSON.parse(newVal);
          this.fileList = imgs.map((a, index) => {
            return {
              uid: a.att_id,
              name: a.att_title,
              url: a.att_url,

            };
          });
        }
        else this.fileList = [];
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.upOption.fileType || this.upOption.fileSize);
    },
    fileTypeName() {
      let typeName = "";
      this.upOption.fileType.forEach((item) => {
        typeName += `${item}，`;
      });
      console.log("typeName", typeName);
      return typeName;
    },
    fileAccept() {
      let fileAccept = "";
      this.upOption.fileType.forEach((element) => {
        fileAccept += `.${element},`;
      });
      return fileAccept;
    },
  },
  created() {
    this.initData();
  },
  methods: {

    /*初始化数据*/
    async initData() {

      if (this.isImport) {
        this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.upOption.fileLimit;
        this.uploadUrl = UPLOAD_FILE_INPORT;
        this.upOption = {
          "fileSize": 5,
          "fileType": ['xls', 'xlsx'],
          "fileLimit": 1,
        };
        this.listType = "text";
      } else {
        if (this.upType == "IMG" || this.upType == "IMGARR") {
          let { data: resImg } = await getGroupData("IMAGECONFIG");
          this.upOption = resImg;
          if (this.isUseSubmitType == 1) {
            this.upOption.fileType = this.fileType
          }
          this.upOption.fileLimit = this.upType == "IMG" ? 1 : this.upOption.fileLimit;
          this.listType = this.upType == "IMG" ? "picture" : "picture-card";

        } else {
          let { data: resFile } = await getGroupData("FILECONFIG");
          console.log("this.upOption", this.upOption);
          this.upOption = resFile;
          if (this.isUseSubmitType == 1) {
            this.upOption.fileType = this.fileType
          }
          console.log("this.upOption", this.upOption);
          this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.upOption.fileLimit;
          this.uploadUrl = UPLOAD_FILE_URL;
          this.listType = "text";
        }
      }
    },

    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.upOption.fileType && file) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.upOption.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });

        if (!isTypeOk && file) {
          this.$message.error(
            `文件格式不正确, 请上传${this.upOption.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.upOption.fileSize && file) {
        const isLt = file.size / 1024 / 1024 < this.upOption.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.upOption.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    handleUploadError(err) {
      this.$message.error("上传失败, 请重试");
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`超出上传文件个数,请删除以后再上传！`);
    },

    // 文件列表移除文件时的钩子000
    handleRemove(file, fileList) {
      this.changeFileList(fileList);
    },
    // 文件列表改变的时候，更新组件的v-model的数据
    changeFileList(fileList) {

      const tempFileList = fileList.map((item) => {
        let tempItem = {};
        if (item.response && item.response.status === 200) {
          tempItem = {
            att_id: item.response.data.att_id,
            att_title: item.response.data.original_name,
            att_url: item.response.data.address
          };
        } else {
          tempItem = {
            att_id: item.uid,
            att_title: item.name,
            att_url: item.url
          };
        }
        return tempItem;
      });
      const tempfileData = JSON.stringify(tempFileList);
      this.$emit("input", tempFileList.length > 0 ? tempfileData : null);
    },


    handleSuccess(res, file, fileList) { // 图片上传服务器后的回调

      if (res.status === 200) {

        let index = 0;
        fileList.map((item) => {
          if ((item.response && item.response.status === 200) || item.status == "success") {
            index++;

          }
        });
        if (fileList.length == index) {
          this.changeFileList(fileList);
        }
      } else {
        this.delShowFile(file, fileList);
        this.$message.error(file.name + '上传失败!');
      }
    },

    // 把上传失败的从list中删除
    delShowFile(file, fileList) {
      let index = fileList.findIndex(item => {
        return item.name === file.name;
      });
      if (index !== -1) {
        fileList.splice(index, 1);
      }
    },


  },
};
</script>
<style lang="scss" scoped>
.images-list {
  border: 1px dashed #d5d5d5;
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}
</style>