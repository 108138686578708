/* 布局 */
//前台
import Layout from "@views/layout";

const gravelatticeRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "gravelattice/list",
            name: "GRAVELATTICE",
            meta: {
                title: "墓区格位管理",
                keepAlive: true
            },
            component: () => import("@views/business/gravelattice/index.vue"),
        },
        {
            path: "gravelattice/detail",
            name: "GRAVELATTICE_DETAIL",
            meta: {
                title: "墓穴详情",
                parentPath: '/business/gravelattice/list',
                operate: true,
            },
            component: () => import("@views/business/gravelatticeaddress/detail.vue"),
        },
    ],
};

export default gravelatticeRouter;
