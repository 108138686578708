import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/api/adminmenu/";


/**
 * 获取菜单列表
 * @param {object} params
 * @param {object} pages
 */
export async function getMenuList(params) {
  try {

    return await API.get(ADRESS + "list", {
      params: {
        ...params,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取菜单详情
 * @param {number} id
 */
export async function getMenuInfo(id) {
  try {
    return await API.post(ADRESS + "info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}



/**
 * 编辑菜单
 * @param {object} params
 */
export async function editMenu(params) {
  try {
    return await API.post(ADRESS + "edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 新增菜单
 * @param {object} params
 */
export async function addMenu(params) {
  try {
    return await API.post(ADRESS + "add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 刪除菜单
 * @param {number} id
 */
export async function deleteMenu(id) {
  try {
    return await API.post(ADRESS + "delete", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改菜单狀態
 * @param {number} id
 */
export async function disableMenu(id, status) {
  try {
    return await API.post(ADRESS + "disable", {
      id: id,
      status: status,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 获取用户授权菜单
*/
export async function getUserMenu() {
  try {
    return await API.post(ADRESS + "getusermenu", {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取用户授权菜单ID清单
*/
export async function getRoleMenuId(roleid, issuper) {
  try {
    return await API.post(ADRESS + "getrolemenuid", { roleid: roleid, issuper: issuper }, {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取树形菜单列表
*/
export async function getTreeMenu() {
  try {
    return await API.post(ADRESS + "gettreemenu", {
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 保存角色清单
*/
export async function saveRoleMenu(roleid, menuarr) {
  try {
    return await API.post(ADRESS + "saverolemenu",
      { roleid: roleid, menuarr: menuarr },
      {
        headers: { isLoading: true },
      });
  } catch (error) {
    return error;
  }
}


