/* 布局 */
//前台
import Layout from "@views/layout";

const driverusercarRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "driverusercar/list",
        name: "DRIVERUSERCAR",
        meta: {
            title: "司机汽车管理",
            keepAlive:true
        },
        component: () => import("@views/business/driverusercar/index.vue"),
    },
        ],
};

export default driverusercarRouter;
