<template>
  <div>
    <div style="{float: isTips && 'left' || ''}">
      <el-select :size="size" filterable :multiple="multiple" :multiple-limit="multiplelimit" :clearable="clearable" :loading="loading"
        :placeholder="placeholder" @change="handleChange" v-model="tValue" id="" :disabled="disabled">
        <el-option v-for="item in optionsData" :key="item[valueKey]" :label="item[labelKey]" :value="item[valueKey]"
          :disabled="item.disabled">
          <slot v-bind:option="item"> </slot>
        </el-option>
      </el-select>
    </div>
    <div v-if="isTips">
      <p style="float:left;padding-left:20px;color: red;">提示：{{ valTips }}</p>
    </div>
  </div>
</template>
<script>
import API from "@utils/request";
import { getDictionaryKeyVal } from '@/api/DataDictionary'
import { Empty } from 'element-ui'
export default {
  name: 'TrySelect',
  props: {
    url: { // 接口地址
      type: String,
      default: undefined
    },
    valueKey: { // 列表中选项的值对应的key
      type: String,
      default: 'value'
    },
    labelKey: { // 列表中选项的标签对应的key
      type: String,
      default: 'key'
    },
    searchKey: { // 模糊搜索的key
      type: String,
      default: 'key'
    },
    dictName: { // 字典值
      type: String,
      default: ''
    },
    isEdit: { // 是否编辑模式
      type: Boolean,
      default: false
    },
    // 绑定的值
    value: {
      type: [String, Number, Array],
      default: undefined
    },
    multiple: { // 是否多选
      type: Boolean,
      default: false
    },
    multiplelimit: { // 多选时用户最多可以选择的项目数，为 0 则不限制
      type: Number,
      default: 0
    },
    size: { // medium/small/mini
      type: String,
      default: 'small'
    },
    placeholder: { //  占位符
      type: String,
      default: '请选择'
    },
    clearable: { // 是否可清空
      type: Boolean,
      default: true
    },
    options: {//数据源
      type: [Array, Object],
      default: undefined
    },
    require: {//是否验证必填
      type: Boolean,
      default: false
    },
    valTips: {//验证提示
      type: String,
      default: '必选项！'
    },
    parameData: {//Url带入参数
      type: [String, Number, Array, Object],
      default: undefined
    },
    disabled: {//禁用属性
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      isTips: false,//是否显示提示
      tValue: this.value,
      optionsData: this.options,
    }
  },
  watch: {
    value(n, o) { // 监听父组件值变动，子组件也要变动
      this.tValue = n

      if (this.require) {
        if ((n == undefined || n.length == 0)) {
          this.isTips = true;
        } else {
          this.isTips = false;
        }
      }
      if ((o === undefined && this.isEdit) || (o !== undefined && o.length === 0 && this.isEdit)) {
        // 如果旧的值等于undefined
        this.requestData();
      }
    },
    options(n, o) {
      this.optionsData = n;
    }
  },
  created() {
    this.requestData();
  },
  methods: {
    // 请求数据
    async requestData() {
      if (this.dictName) {

        this.loading = true;
        let { data: res } = await getDictionaryKeyVal(this.dictName);
        this.optionsData = res;
        this.loading = false;
      } else if (this.url) {

        try {
          let { data: res } = await API.post(this.url, eval('(' + this.parameData + ')'));
          this.optionsData = res;

        } catch (error) {

          return error;
        }
      }
    },
    // 子组件值变化要通过父组件
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  }
}
</script>
