/* 布局 */
//前台
import Layout from "@views/layout";

const specialRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "special/list",
        name: "SPECIAL",
        meta: {
            title: "专题管理",
            keepAlive:true
        },
        component: () => import("@views/business/special/index.vue"),
    },
        ],
};

export default specialRouter;
