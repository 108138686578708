/* 布局 */
//前台
import Layout from "@views/layout";

const sacrificematerialRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "sacrificematerial/list",
        name: "SACRIFICEMATERIAL",
        meta: {
            title: "云祭祀素材管理",
            keepAlive:true
        },
        component: () => import("@views/business/sacrificematerial/index.vue"),
    },
        ],
};

export default sacrificematerialRouter;
