/* 布局 */
//前台
import Layout from "@views/layout";

const goodsspecoptionsRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "goodsspecoptions/list",
        name: "GOODSSPECOPTIONS",
        meta: {
            title: "商品规格值扩展",
            keepAlive:true
        },
        component: () => import("@views/business/goodsspecoptions/index.vue"),
    },
        ],
};

export default goodsspecoptionsRouter;
