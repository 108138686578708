/* 布局 */
//前台
import Layout from "@views/layout";

const propertywarehousebatchRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "propertywarehousebatch/list",
        name: "PROPERTYWAREHOUSEBATCH",
        meta: {
            title: "资产入库批次",
            keepAlive:true
        },
        component: () => import("@views/business/propertywarehousebatch/index.vue"),
    },
        ],
};

export default propertywarehousebatchRouter;
