/* 布局 */
//前台
import Layout from "@views/layout";

const ordersacrificeRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "ordersacrifice/list",
        name: "ORDERSACRIFICE",
        meta: {
            title: "网上代祭祀订单",
            keepAlive:true
        },
        component: () => import("@views/business/ordersacrifice/index.vue"),
    },
        ],
};

export default ordersacrificeRouter;
