/* 布局 */
//前台
import Layout from "@views/layout";

const usermessageRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "usermessage/list",
        name: "USERMESSAGE",
        meta: {
            title: "用户投诉建议",
            keepAlive:true
        },
        component: () => import("@views/business/usermessage/index.vue"),
    },
        ],
};

export default usermessageRouter;
