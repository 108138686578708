/* 布局 */
//前台
import Layout from "@views/layout";

const graveRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "grave/list",
            name: "GRAVE",
            meta: {
                title: "公墓管理",
                keepAlive: true
            },
            component: () => import("@views/business/grave/index.vue"),
        },
    ],
};

export default graveRouter;
