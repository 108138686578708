/* 布局 */
//前台
import Layout from "@views/layout";

const reservationcremationreserveRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "reservationcremationreserve/list",
        name: "RESERVATIONCREMATIONRESERVE",
        meta: {
            title: "遗体火化管理",
            keepAlive:true
        },
        component: () => import("@views/business/reservationcremationreserve/index.vue"),
    },
        ],
};

export default reservationcremationreserveRouter;
