import axios from "axios";
// import Qs from 'qs
import { MessageBox, Message } from "element-ui";
import { API_ROOT, REQUEST_TIMEOUT, DEBUG,ENCRYPT_OPEN, ENCRYPT_KEY, ENCRYPT_VIM  } from "@config";
import router from "@router";
import CryptoJS from 'crypto-js'
let loadingInstance; //加载

/**
 * 创建一个axios实例
 */
const service = axios.create({
  baseURL: API_ROOT, // 请求地址
  timeout: REQUEST_TIMEOUT, // 请求超时
  // withCredentials: true, // 在跨域请求时发送cookie

  // 请求参数的格式
  headers: {
    "Content-Type": "application/json",
    isLoading: "true",
  },

});

/**
 * 请求拦截器
 * 在请求发送之前做什么
 */
service.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem("token")) {
      // 让每个请求都带有令牌，请根据实际情况修改
      config.headers["accessToken"] = sessionStorage.getItem("token");
      config.headers["userTage"] =encryptByDES(String(sessionStorage.getItem("username")), ENCRYPT_KEY, ENCRYPT_VIM) ;
    }
    if (config.params != undefined && ENCRYPT_OPEN) {
      let paramsString = JSON.stringify(config.params);
      paramsString = encryptByDES(String(paramsString), ENCRYPT_KEY, ENCRYPT_VIM);
      config.params = {
        paramsData: paramsString
      };
    }

    if (config.data != undefined && ENCRYPT_OPEN) {
      let paramsString = JSON.stringify(config.data);
      paramsString = encryptByDES(String(paramsString), ENCRYPT_KEY, ENCRYPT_VIM);
      config.data = {
        paramsData: paramsString
      };
    }

    return config;
  },
  (error) => {
    // 请求错误时做了什么

    if (DEBUG) console.log("requestError：" + error);

    return Promise.reject(error);
  }
);

/**
 * 响应拦截器
 * 如果您想获得http信息，如头信息或状态
 */
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 加载层清除
    // if (loadingInstance) loadingInstance.close();

    /**
     * 通过自定义代码确定请求状态
     * 这里只是一个例子
     * 您也可以通过HTTP状态码来判断状态
     */
    switch (res.status) {
      case 1:
        if (response.config.responseType === "blob") {
          //文件传输入
          return handleResponse(res);
        } else if (res.status == 1) {
          if (ENCRYPT_OPEN && res.data.length > 0) {
            res.data = eval("(" + decryptByDES(res.data, ENCRYPT_KEY, ENCRYPT_VIM) + ")");
          }
          return res;
        } else {
          Message.error(res.msg ? res.msg : "接口出错！");
          return res;
        }
      case 200:
        if (response.config.responseType === "blob") {
          //文件传输入
          return handleResponse(res);
        } else if (res.status == 200) {
          return res;
        } else {
          Message.error(res.msg ? res.msg : "接口出错！");
          return res;
        }
      case 201:
        Message.error(res.msg ? res.msg : "接口出错！");
        return res;
      case 401:
        Message.error("401，登录失效！");
        sessionStorage.clear();
        setTimeout(() => {
          window.location="/#/login";
        }, 1000);
        break;

      case 403:
        Message.error("403，暂无权限！");
        break;

      case 404:
        Message.error("404，接口不存在！");
        break;

      case 500:
        Message.error("500，内部服务器错误！");
        break;

      default:
        Message.error("网络错误，请稍后重试！");
    }

    return Promise.reject(new Error(res.message || "Error"));
  },
  (error) => {
    // 加载层清除
    if (loadingInstance) loadingInstance.close();

    if (DEBUG) console.log("responseError：" + error);

    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });

    return Promise.reject(error);
  }
);

/**
 * 处理响应的二进字数据
 * @param {*} response
 */
function handleResponse(response) {
  /**
   * 从响应头获取文件名称
   * 后端返回的文件名称为十六进制
   */
  const fileName = decodeURI(res.headers["content-disposition"].split("=")[1]);

  try {
    const blob = new Blob([response]);
    if ("download" in document.createElement("a")) {
      // 非IE下载
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob); //创建下载
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName);
    }
  } catch (e) { }
}

//AES加密
function encryptByDES(string, key, ivstr) {
  let KeyHex = CryptoJS.enc.Utf8.parse(key);
  let encrypted = CryptoJS.DES.encrypt(string,
    KeyHex, {
    mode: CryptoJS.mode.CBC,  // ecb模式不需要偏移量
    padding: CryptoJS.pad.Pkcs7,
    iv: CryptoJS.enc.Utf8.parse(ivstr)
  });

  // return encrypted.toString(); //此方式返回base64格式密文
  return encrypted.ciphertext.toString() // 返回hex格式的密文
}
//AES解密
function encryptByAES(string, key) {
  let ckey = CryptoJS.enc.Utf8.parse(key);
  let encrypted = CryptoJS.AES.encrypt(string, ckey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  // return encrypted.toString(); //此方式返回base64格式密文
  return encrypted.ciphertext.toString(); // 返回hex格式的密文
}

//AES解密
function decryptByAES(string, key) {
  let ckey = CryptoJS.enc.Utf8.parse(key);
  let ciphertext = CryptoJS.enc.Hex.parse(string);
  let srcs = CryptoJS.enc.Base64.stringify(ciphertext);
  let decrypt = CryptoJS.AES.decrypt(srcs, ckey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  let decrypted = decrypt.toString(CryptoJS.enc.Utf8);
  console.log(decrypted);
  //return decrypted.toString(); 
  return decrypted.toString(CryptoJS.enc.Utf8);
}
//DES解密
function decryptByDES(ciphertext, key, ivstr) {
  let keyHex = CryptoJS.enc.Utf8.parse(key);
  let decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Hex.parse(ciphertext)
  }, keyHex, {
    iv: CryptoJS.enc.Utf8.parse(ivstr),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export default service;
