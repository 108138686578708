<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量

.header {
  width: 100%;
  height: 50px;
  border-bottom: $borderSlide;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  display: flex;
  align-items: center;

  .tool-left {
    flex: 1 1 0%;
    overflow: hidden;
    margin-right: 20px;
    // float: left;
  }

  .tool-right {
    flex-shrink: 0;
    // float: right;
  }

  .tool-left,
  .tool-right {
    height: 100%;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;

    .tool-item {
      color: $color-text-regular;
      cursor: pointer;
      padding: 0 12px;
      height: 50px;
      display: flex;
      align-items: center;

      &:hover {
        background: $tool-hover-bg;
      }

      .user-name {
        font-size: 14px;
        color: $color-text-regular;
        margin-left: 4px;

        span {
          margin-left: 4px;
        }
      }
    }
  }

  .tool-left {
    display: flex;
    align-items: center;
    .tool-el-tabs {
      flex: 1 1 0%;
      overflow: hidden;
    }
    .tool-item:first-child {
      padding-left: 0;
    }
  }
}
</style>

<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

.el-badge__content {
  padding: 0 5px;
}

.el-badge__content.is-fixed {
  top: 20px;
  right: 24px;
}

.el-dropdown-menu__item span {
  margin-right: 5px;
}

.el-breadcrumb__inner,
.el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
  color: $color-text-secondary;
}

.tool-el-tabs .el-tabs {
  margin-top: -2px;
  padding-top: 0px;
}

.tool-el-tabs .el-tabs,
.tool-el-tabs .el-tabs__item {
  height: 48px;
  line-height: 48px;
  color: #303133;
}

.tool-el-tabs .el-tabs__item:hover {
  background-color: transparent !important;
  color: #1890ff;
}

.tool-el-tabs .el-tabs__item.is-active::after {
  display: none;
}

.tool-el-tabs .el-tabs__nav-wrap::after {
  display: none;
}

.tool-el-tabs .el-tabs__item.is-active {
  background-color: transparent !important;
}

.tool-el-tabs .el-tabs__nav-next,
.tool-el-tabs .el-tabs__nav-prev {
  top: 50%;
  transform: translateY(-50%);
}

.header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0 !important;
  position: relative;

  .logo {
    width: 64px;
    height: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
    font-size: 20px;
    float: left;
    color: $menuTextColor;
    cursor: pointer;
    color: #333;
    font-weight: normal;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
    transition: 0.3s;
    transform-origin: left center;
    flex-shrink: 0;
    overflow: hidden;

    img {
      width: 30px;
    }

    span {
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  .txt-logo {
    // width: 120px;
    width: 220px;
  }
}

.badge-item {
  .el-badge__content.is-fixed {
    top: 17px !important;
    right: 4px !important;
  }
}
</style>

<template>
  <section class="header">
    <h1
      class="logo"
      :class="isCollapse == false ? 'txt-logo' : ''"
      @click="targetIndex"
    >
      <img src="@assets/logo.png" alt style="width: 32px; height: 32px" />
      <span v-show="!isCollapse" style="font-size: 16px">{{ sysTitle }}</span>
    </h1>
    <!-- 左侧工具栏 -->
    <div class="tool-left">
      <!-- 导航栏收起/展开 -->
      <div class="tool-item" @click="handleChangeCollapse">
        <div v-if="isCollapse" class="iconfont">&#xe8fc;</div>
        <div v-if="!isCollapse" class="iconfont">&#xe600;</div>
      </div>
      <el-tabs
        class="tool-el-tabs"
        v-model="menuActiveName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          :name="item.amenu_id + ''"
          v-for="item in menulist"
          :key="item.amenu_id"
          :index="item.amenu_id + ''"
          :item="item"
        >
          <span slot="label">
            <el-badge
              :value="item.wait_num"
              class="badge-item"
              :hidden="item.wait_num == 0"
            >
              {{ item.amenu_name }}
            </el-badge>
          </span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 右侧工具栏 -->
    <div class="tool-right">
      <!-- 全屏展开/收起 -->
      <div class="tool-item" @click="handleFullScreen">
        <div class="iconfont">{{ (isFull && "&#xe641;") || "&#xe8fa;" }}</div>
      </div>
      <el-dropdown @command="handleDropdownItem">
        <div class="tool-item">
          <el-avatar
            icon="el-icon-user-solid"
            :size="28"
            v-if="avatarurl == ''"
          ></el-avatar>
          <el-avatar
            :size="28"
            :src="avatarurl"
            v-if="avatarurl != ''"
          ></el-avatar>
          <p class="user-name">
            {{ username }}
          </p>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="modifyPwd">
            <span class="el-icon-key" />修改密码
          </el-dropdown-item>
          <el-dropdown-item divided command="clearCache">
            <span class="el-icon-delete" />清除缓存
          </el-dropdown-item>
          <el-dropdown-item divided command="loginOut">
            <span class="el-icon-switch-button" />退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog title="修改密码" :visible.sync="pwdDialogVisible" width="420px">
      <el-form :model="pwdForm" :rules="rules" ref="pwdForm" label-width="84px">
        <el-form-item label="原密码:" prop="oldPwd">
          <el-input
            type="password"
            v-model="pwdForm.oldPwd"
            placeholder="请输入原密码"
          />
        </el-form-item>
        <el-form-item label="新密码:" prop="newPwd">
          <el-input
            type="password"
            v-model="pwdForm.newPwd"
            placeholder="请输入新密码"
          />
        </el-form-item>
        <el-form-item label="确认密码:" prop="newPwdTwo">
          <el-input
            type="password"
            v-model="pwdForm.newPwdTwo"
            placeholder="请再次输入密码"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { validPassword } from "@utils/validate"; //引入密码校验规则
import { mapActions } from "vuex";
import { uppassword, clearCache, getGroupItemValue } from "@api/AdminUser";
import { TITLE } from "@config"; //引入系统Title
export default {
  name: "Navbar",
  inject: ["reload"],
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
    menulist: {
      type: [String, Number, Array, Date],
      default: "",
    },
    active: {
      type: String,
      default: 0,
    },
  },
  data() {
    // 初次输入新密码校验规则
    let newPwdValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码！"));
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error("密码长度在 8 到 20 个字符"));
        } else if (!validPassword(value)) {
          callback(new Error("必须包含数字、大小写字母、特殊符号"));
        }
        callback();
      }
    };
    // 确认密码校验规则
    let newPwd2Validator = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请确认密码！"));
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error("密码长度在 8 到 20 个字符"));
        } else if (value != this.pwdForm.newPwd) {
          callback(new Error("两次输入密码不相同！"));
        }
        callback();
      }
    };
    return {
      menuActiveName: this.active,
      sysTitle: "",
      isFull: false, //全屏显示
      activeName: "1", //消息通知高亮栏目
      breadcrumbData: [], // 面包屑数据
      pwdDialogVisible: false, //修改密码弹窗显示
      pwdForm: {}, //表单存储数据
      username: "",
      avatarurl: "",
      rules: {
        oldPwd: [
          { required: true, message: "请输入原密码！", trigger: "blur" },
        ],
        newPwd: [
          { required: true, validator: newPwdValidator, trigger: "blur" },
        ],
        newPwdTwo: [
          { required: true, validator: newPwd2Validator, trigger: "blur" },
        ],
      }, //表单校验规则
    };
  },
  watch: {
    $route(val) {
      this.breadcrumbData = val.matched;
    },
    active(val) {
      this.menuActiveName = val;
    },
  },
  created() {
    this.breadcrumbData = this.$route.matched;

    this.username = sessionStorage.getItem("usernickname");
    this.avatarurl =
      sessionStorage.getItem("avatar") != ""
        ? sessionStorage.getItem("avatar")
        : "";
  },
  async mounted() {
    let { data: restitle } = await getGroupItemValue("SYSTITLE");
    this.sysTitle = restitle.dgi_value ? restitle.dgi_value : TITLE;
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
    });
  },
  methods: {
    ...mapActions("system", ["getBranchList", "getMenuList"]),
    ...mapActions("dictionaries", ["setDictionaries"]),
    ...mapActions("dicttree", ["setDicttree"]),
    ...mapActions("configdata", ["setDataConfig"]),
    ...mapActions("businessdata", ["setBusinessData"]),
    targetIndex() {
      if (this.$route.path != "/homepage") {
        this.$router.replace("/homepage");
      }
    },
    /** 收起/展开导航栏 */
    handleChangeCollapse() {
      this.$emit("handleChangeCollapse");
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 点击全屏 */
    handleFullScreen() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request();
      } else {
        this.$screenfull.exit();
      }
    },
    //切换头部导航
    handleClick(tab, event) {
      this.$nextTick(() => {
        this.menuActiveName = tab.name + "";
      });
      const menuId = tab.name;
      const menu = this.menulist;

      const childMenu = menu.find((item, i) => {
        return item.amenu_id == menuId;
      });
      // 取子级的第一项进行跳转
      let childPath = childMenu.children[0].amenu_address;
      if (childMenu.children[0].children.length != 0) {
        childPath = childMenu.children[0].children[0].amenu_address;
      }
      // 对应目标的子菜单存储到本地
      this.$emit("changeLeftMenu", childMenu.children);
      // localStorage.setItem('leftmenu', JSON.stringify(childMenu.children))
      this.$router.push(childPath);
    },
    /** 消息类型切换 */
    handleTabClick() {
      // console.log("消息通知栏目切换");
    },
    /** 下拉列表项点击 */
    handleDropdownItem(command) {
      switch (command) {
        case "modifyPwd":
          // 修改密码
          this.pwdForm = {};
          this.pwdDialogVisible = true;
          break;
        case "loginOut":
          // 退出登录
          this.$confirm("请确认是否退出登录?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              sessionStorage.clear();
              this.$router.replace("/login");
            })
            .catch(() => {});

          break;
        case "clearCache":
          // 清除缓存
          clearCache().then((res) => {
            if (res.status == 200) {
              this.setDictionaries();
              this.setDicttree();
              this.setDataConfig();
              this.setBusinessData();
              this.$message({ message: "清除成功", type: "success" });
              return false;
            } else {
              this.$message({ message: "清除失败", type: "error" });
              return false;
            }
          });

          break;
        default:
          break;
      }
    },
    /** 取消修改密码 */
    handleCancle() {
      this.pwdDialogVisible = false;
      this.pwdForm = {};
      this.$refs["formData"].clearValidate();
    },
    /** 提交密码修改 */
    handleSubmit() {
      /**
       * 请求接口，修改密码
       */
      this.$refs["pwdForm"].validate((valid) => {
        if (valid) {
          uppassword(this.pwdForm).then((res) => {
            if (res.status != 200) return;
            this.$message({ message: res.msg, type: "success" });
            this.pwdDialogVisible = false;
            // 退出登录
            sessionStorage.clear();
            this.$router.replace("/login");
          });
        }
      });
    },
  },
};
</script>
