/* *********************** 正式环境 ********************** */
export const API_ROOT = "/";
export const UPLOAD_IMG_URL = "/api/tool/uploadimg/";
export const REPLACE_IMG_URL = "/api/tool/replaceimg/";
export const UPLOAD_FILE_URL = "/api/tool/uploadfile/";
export const UPLOAD_FILE_SLICE = "/api/tool/uploadslice";
export const UPLOAD_FILE_MEDIA = "/api/tool/uploadmedia";
export const UPLOAD_FILE_INPORT = "/api/tool/inportfile/";
/* *********************** 测试环境 ********************** */
// export const API_ROOT = "https://www.xxx.com/api";
//export const UPLOAD_IMG_URL = "/admin/tool/uploadimg/";
//export const REPLACE_IMG_URL = "/admin/tool/replaceimg/";
//export const UPLOAD_FILE_URL = "/admin/tool/uploadfile/";
//export const UPLOAD_FILE_SLICE = "/admin/tool/uploadslice";
//export const UPLOAD_FILE_MEDIA = "/admin/tool/uploadmedia";
//export const UPLOAD_FILE_INPORT = "/admin/tool/inportfile/";
// 系统标题
export const TITLE = "T-CFS";

// 系统中文标题
export const CN_LOGINTITLE = "创企科技系统登录";

// 系统版权
export const COPYRIGHT = "Copyright©2016-2023 四川创企科技有限责任公司版权所有";

// 生产环境和测试环境
// export const DEBUG = process.env.NODE_ENV !== "production";
export const DEBUG = true;

// 设置请求的超时时间   单位毫秒
export const REQUEST_TIMEOUT = 300000;

// 文件服务器地址
export const FILE_ROOT = "";

// 一页显示的条目
export const PAGE_SIZE = 15;

// 当前版本
export const VERSION = "1.0.0";


// 是否开启加密
export const ENCRYPT_OPEN = false;
// 加密秘钥:长度16个字符
export const ENCRYPT_KEY = "trycheersfactory";  

// 加密偏移量:长度8个字符
export const ENCRYPT_VIM = "try_code";  

// wordPaster编辑器上传地址
export const WORDPASTER_URL = "http://www.ncmem.com:9010/admin/tool/uploadimg";
