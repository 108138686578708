/* 布局 */
import Layout from "@views/layout";

const cfsRouter = {
  path: "/system",
  name: "SYSTEM",
  component: Layout,
  redirect: { name: "USER" },
  meta: {
    title: "表单设计",
  },
  children: [

    {
      path: "moduleapi",
      name: "ModuleApi",
      meta: {
        title: "前台设计",
        sort: 1,
      },
      component: () => import("@views/system/generate/api/index.vue"),
      redirect: {
        name: "ModuleApi_LIST",
      },
      children: [
        {
          path: "list",
          name: "ModuleApi_LIST",
          meta: {
            title: "前台设计",
            sort: 2,
          },
          component: () => import("@views/system/generate/api/module.vue"),
        },
      ],
    },
  ],
};

export default cfsRouter;
