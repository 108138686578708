/* 布局 */
//前台
import Layout from "@views/layout";

const graveareaaddressRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "graveareaaddress/list",
            name: "GRAVEAREAADDRESS",
            meta: {
                title: "墓穴管理",
                keepAlive: true
            },
            component: () => import("@views/business/graveareaaddress/index.vue"),
        },
        {
            path: "graveareaaddress/detail",
            name: "GRAVEAREAADDRESS_DETAIL",
            meta: {
                title: "墓穴详情",
                parentPath: '/business/gravearea/list',
                operate: true,
            },
            component: () => import("@views/business/graveareaaddress/detail.vue"),
        },
    ],
};

export default graveareaaddressRouter;
