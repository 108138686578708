/* 布局 */
//前台
import Layout from "@views/layout";

const propertywarehouselogRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "propertywarehouselog/list",
        name: "PROPERTYWAREHOUSELOG",
        meta: {
            title: "资产出入库记录",
            keepAlive:true
        },
        component: () => import("@views/business/propertywarehouselog/index.vue"),
    },
        ],
};

export default propertywarehouselogRouter;
