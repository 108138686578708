/* 布局 */
//前台
import Layout from "@views/layout";

const orderflowerRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "orderflower/list",
        name: "ORDERFLOWER",
        meta: {
            title: "鲜花预约订单",
            keepAlive:true
        },
        component: () => import("@views/business/orderflower/index.vue"),
    },
        ],
};

export default orderflowerRouter;
