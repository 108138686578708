/* 布局 */
//前台
import Layout from "@views/layout";

const orderburialRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "orderburial/list",
        name: "ORDERBURIAL",
        meta: {
            title: "安葬预约订单",
            keepAlive:true
        },
        component: () => import("@views/business/orderburial/index.vue"),
    },
        ],
};

export default orderburialRouter;
