/* 布局 */
//前台
import Layout from "@views/layout";

const goodsleasecarRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "goodsleasecar/list",
        name: "GOODSLEASECAR",
        meta: {
            title: "租车路线套餐",
            keepAlive:true
        },
        component: () => import("@views/business/goodsleasecar/index.vue"),
    },
        ],
};

export default goodsleasecarRouter;
