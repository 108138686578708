/* 布局 */
//前台
import Layout from "@views/layout";

const userbuycarRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "userbuycar/list",
        name: "USERBUYCAR",
        meta: {
            title: "用户购物车管理",
            keepAlive:true
        },
        component: () => import("@views/business/userbuycar/index.vue"),
    },
        ],
};

export default userbuycarRouter;
