/* 布局 */
//前台
import Layout from "@views/layout";

const propertyclassRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
        path: "propertyclass/list",
        name: "PROPERTYCLASS",
        meta: {
            title: "资产分类管理",
            keepAlive:true
        },
        component: () => import("@views/business/propertyclass/index.vue"),
    },
        ],
};

export default propertyclassRouter;
